import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const MintRafflePrizes = ({ collectionStats, loadingCollectionStats }) => {
    const rulesRef = useRef(null);
    const [isAtTop, setIsAtTop] = useState(true);

    const scrollToRules = () => {
        if (rulesRef.current) {
            rulesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleScroll = () => {
        setIsAtTop(window.scrollY === 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="flex flex-col items-center text-center justify-center min-h-screen p-6 border-2 border-lime-500" style={{ fontFamily: 'Pixelify Sans, sans-serif' }}>
            <h1 className="text-5xl font-bold mb-4 text-lime-500">The Big Raffle has concluded on <span style={{ fontFamily: 'Inter, sans-serif' }}>02/27/25</span></h1>
            <div className="text-2xl text-white  border-2 border-solid border-white pl-2 pr-2">
                <p style={{ fontFamily: 'Inter, sans-serif' }}>
                   {!loadingCollectionStats && collectionStats ? `${collectionStats.supply - 339} total entries` : 'Loading...'}
                </p>
                
            </div>
            <p className="text-xl mb-6 text-white cursor-pointer hover:text-lime-600" onClick={scrollToRules}>
                    Go to rules
                </p>

            <p className="text-lg md:text-2xl mb-2 text-lime-500 mb-6">
                Raffle prizes are stackable with {' '}
                <Link to="/mint-bounties" className="animate-[pulse_2s_infinite] text-white cursor-pointer hover:text-lime-600" title="Learn more about the Bounties">MINT BOUNTIES</Link> ...
            </p>
            <p className="animate-[pulse_1s_infinite] text-3xl hover:text-lime-600 text-white cursor-pointer" title="Go mint one WO7MS" onClick={() => window.open('https://asteroidprotocol.io/app/launchpad/REALWO7MS', '_blank')}>
                ➡️ MINT 1 WO7MS ⬅️
            </p> 

            <div className="flex border border-solid-1 border-gray-700 pl-2 pr-2 mt-2 mb-6 cursor-pointer"
            onClick={() => window.open('https://go.skip.build?dest_chain=cosmoshub-4&dest_asset=uatom', '_blank')}
            title="Swap from any chain to ATOM" >
                <p className="text-xl mb-2 text-white mt-2 pr-2 opacity-50" title="Swap from any chain to ATOM">Need 1 ATOM ? Use {' '}</p>
                <img className="max-w-16 item-center pb-2 opacity-80" src="./SkipLogo.svg" alt="SkipLogo" />
            </div>
                    
            <h3 className="text-3xl font-bold mb-4 text-lime-600">PRIZES and winning WO7MS :</h3>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 justify-items-center text-center space-x-4">
    {/* Item 0 */}
    <div className="flex flex-col place-items-center">
        <p title="ONCHAIN OMIE #1540 details on Stargaze" 
           onClick={() => window.open('https://www.stargaze.zone/m/stars1j5fhf04q6sycz72mz5uzrwvv2e05jy3npsdzppxyl2eww0x5hy4s0wuftp/1540', '_blank')} 
           className="text-2xl ml-1 mb-2 hover:text-lime-600 text-white cursor-pointer">
            <span className="text-4xl mb-2 text-white">{' '}1.{' '}</span>
            ONCHAIN OMIE<span style={{ fontFamily: 'Inter, sans-serif' }}> #1540</span> - STARGAZE
        </p>
                <div className="relative w-80 h-80 mb-4">
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02] blur-sm" 
            src="https://ipfs-gw.stargaze-apis.com/ipfs/bafybeighnzdhflbpqxso3p4tnxpubxfjtaav3mnz57cgadk4h72kekmrky/1540.png" 
            alt="Onchain OMie" 
            title="Onchain OMie #1540 details on Stargaze"
            onClick={() => window.open('https://www.stargaze.zone/m/stars1j5fhf04q6sycz72mz5uzrwvv2e05jy3npsdzppxyl2eww0x5hy4s0wuftp/1540', '_blank')}
        />
                {/* Overlay for "CLAIMED" */}
                <div className="absolute inset-0 flex items-center justify-center">
                    <span className="text-red-600 text-6xl font-bold px-4 py-2 rotate-[-30deg]">
                        CLAIMED
                    </span>
                </div>
                <div style={{ paddingTop: '100%' }} /> {/* Maintains aspect ratio */}
                </div>
    </div>

    {/* Item 1 */}
    <div className="flex flex-col place-items-center">
        <p title="Mad Scientists #1009 details on Backbonelabs" 
           onClick={() => window.open('https://app.backbonelabs.io/nfts/necropolis/collections/osmo16pwjh09s662a0j2ssmzauyvkvagjwd9kpwc0mtamlwr8dtznlhfqcweap6/7875', '_blank')} 
           className="text-2xl mb-2 hover:text-lime-600 text-white cursor-pointer">
            <span className="text-4xl mb-2 text-white">{' '}2.{' '}</span>
            MAD SCIENTIST<span style={{ fontFamily: 'Inter, sans-serif' }}> #1009</span> - OSMOSIS
        </p>
                <div className="relative w-80 h-80 mb-4">
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02]" 
            src="./madscientist.jpg" 
            alt="mad waiting" 
            title="Mad Scientists #1009 details on Backbonelabs"
            onClick={() => window.open('https://app.backbonelabs.io/nfts/necropolis/collections/osmo16pwjh09s662a0j2ssmzauyvkvagjwd9kpwc0mtamlwr8dtznlhfqcweap6/7875', '_blank')}
        />
                {/* Overlay for "CLAIMED" */}
                <div className="absolute inset-0 flex items-center justify-center cursor-pointer"
                onClick={() => window.open('https://asteroidprotocol.io/app/inscription/DA6A7178DB98A47E8265705434282FB5F67C7534E190921BA35551155482912D', '_blank')}>
                    <span className="text-lime-500 bg-white text-4xl font-bold px-4 py-2 opacity-80">
                        WO7MS <span style={{ fontFamily: 'Inter, sans-serif' }}>#418</span>
                    </span>
                </div>
                <div style={{ paddingTop: '100%' }} /> {/* Maintains aspect ratio */}
                </div>
    </div>

    {/* Item 2 */}
    <div className="flex flex-col place-items-center">
            <p 
                title="Roncorin Wilnir details on Stargaze" 
                onClick={() => window.open('https://www.stargaze.zone/m/stars18d7ver7mmjdt06mz6x0pz09862060kupju75kpka5j0r7huearcsq0gyg0/376', '_blank')} 
                className="text-2xl mb-2 hover:text-lime-600 text-white cursor-pointer"
            >
                <span className="text-4xl mb-2 text-white">{' '}3.{' '}</span>
                PIXEL WIZARD<span style={{ fontFamily: 'Inter, sans-serif' }}>   #376</span> - STARGAZE
            </p>
            <div className="relative w-80 h-80 mb-4">
                <iframe 
                    sandbox="allow-scripts" 
                    src="https://ipfs-gw.stargaze-apis.com/ipfs/bafybeiet7wzhih3zwcmdi2kojzpkrhjdrp7otaineans5zgg6e26yuj4qu/376.svg" 
                    title="Pixel Wizard" 
                    style={{ border: 'none', width: '100%', height: '100%', display: 'block' }} 
                    className="absolute inset-0 blur-sm"
                ></iframe>
                {/* Overlay for "CLAIMED" */}
                <div className="absolute inset-0 flex items-center justify-center">
                    <span className="text-red-600 text-6xl font-bold px-4 py-2 rotate-[-30deg]">
                        CLAIMED
                    </span>
                </div>
                <div style={{ paddingTop: '100%' }} /> {/* Maintains aspect ratio */}
            </div>
        </div>

{/* Item 3 */}
<div className="flex flex-col place-items-center">
        <p title="Frutopia #778 details on Stargaze" 
           onClick={() => window.open('https://www.stargaze.zone/m/frutopia/778', '_blank')} 
           className="text-2xl mb-2 hover:text-lime-600 text-white cursor-pointer">
            <span className="text-4xl mb-2 text-white">{' '}4.{' '}</span>
            FRUTOPIA <span style={{ fontFamily: 'Inter, sans-serif' }}> #778</span> - STARGAZE
        </p>
                <div className="relative w-80 h-80 mb-4">
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02]" 
            src="https://ipfs-gw.stargaze-apis.com/ipfs/bafybeiayy7zuvahdxzlbvrihtgdiwtbugckdwjmcz6aaszckiwdymidkra" 
            alt="Frutopia" 
            title="Frutopia #778 details on Stargaze"
            onClick={() => window.open('https://www.stargaze.zone/m/frutopia/778', '_blank')}
        />
                <div className="absolute inset-0 flex items-center justify-center cursor-pointer"
                onClick={() => window.open('https://asteroidprotocol.io/app/inscription/79F187A3C6D05351A726E92E3FCAA2FCDA0CC8ADE2448C4915A96746318C0DAD', '_blank')}>
                    <span className="text-lime-500 bg-white text-4xl font-bold px-4 py-2 opacity-80">
                        WO7MS <span style={{ fontFamily: 'Inter, sans-serif' }}>#970</span>
                    </span>
                </div>
                <div style={{ paddingTop: '100%' }} /> {/* Maintains aspect ratio */}
                </div>
    </div>

    {/* Item 6 */}
    <div className="flex flex-col place-items-center">
        <p title="Drama Queens #1157 details on Stargaze" 
           onClick={() => window.open('https://www.stargaze.zone/m/dramaqueens/1157', '_blank')} 
           className="text-2xl mb-2 hover:text-lime-600 text-white cursor-pointer">
            <span className="text-4xl mb-2 text-white">{' '}5.{' '}</span>
            DRAMA QUEENS<span style={{ fontFamily: 'Inter, sans-serif' }}> #1157</span> - STARGAZE
        </p>
                <div className="relative w-80 h-80 mb-4">
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02]" 
            src="https://ipfs-gw.stargaze-apis.com/ipfs/bafybeibhs2db2hthmlnwfvbuduvorybvazltxdmir5w4zoidhzfrbmyvom/1157.png" 
            alt="Drama Queen" 
            title="Drama Queens #1157 details on Stargaze"
            onClick={() => window.open('https://www.stargaze.zone/m/dramaqueens/1157', '_blank')}
        />
                <div className="absolute inset-0 flex items-center justify-center cursor-pointer"
                onClick={() => window.open('https://asteroidprotocol.io/app/inscription/8022015FFA68F162B2FDDC5D4C777C7A5B475922FA3EBFE553EE4DFA7B7EE1CF', '_blank')}>
                    <span className="text-lime-500 bg-white text-4xl font-bold px-4 py-2 opacity-80">
                        WO7MS <span style={{ fontFamily: 'Inter, sans-serif' }}>#651</span>
                    </span>
                </div>
                <div style={{ paddingTop: '100%' }} /> {/* Maintains aspect ratio */}
                </div>
    </div>

    {/* Item 6 */}
    <div className="flex flex-col place-items-center">
        <p title="The Dunces #1290 details on Stargaze" 
           onClick={() => window.open('https://www.stargaze.zone/m/theduncess/1290', '_blank')} 
           className="text-2xl mb-2 hover:text-lime-600 text-white cursor-pointer">
            <span className="text-4xl mb-2 text-white">{' '}6.{' '}</span>
            THE DUNCES<span style={{ fontFamily: 'Inter, sans-serif' }}> #1290</span> - STARGAZE
        </p>
                <div className="relative w-80 h-80 mb-4">
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02]" 
            src="https://ipfs-gw.stargaze-apis.com/ipfs/bafybeiazbczjqfthy5d7jasqvktdybxya7epjauknmnil2jbxpzzgehvsu/1290.jpeg" 
            alt="The Dunces" 
            title="The Dunces #1290 details on Stargaze"
            onClick={() => window.open('https://www.stargaze.zone/m/theduncess/1290', '_blank')}
        />
                <div className="absolute inset-0 flex items-center justify-center cursor-pointer"
                onClick={() => window.open('https://asteroidprotocol.io/app/inscription/A2C27939D40BB3CEA8CDF5100926C377D18EF38105C112D1F01B5440412B4516', '_blank')}>
                    <span className="text-lime-500 bg-white text-4xl font-bold px-4 py-2 opacity-80">
                        WO7MS <span style={{ fontFamily: 'Inter, sans-serif' }}>#276</span>
                    </span>
                </div>
                <div style={{ paddingTop: '100%' }} /> {/* Maintains aspect ratio */}
                </div>
    </div>

    {/* Item 3 */}
    <div className="flex flex-col place-items-center">
        <p title="Ammelia #1580 details on Stargaze" 
           onClick={() => window.open('https://www.stargaze.zone/m/ammelia/1580', '_blank')} 
           className="text-2xl mb-2 hover:text-lime-600 text-white cursor-pointer">
            <span className="text-4xl mb-2 text-white">{' '}7.{' '}</span>
            AMMELIA <span style={{ fontFamily: 'Inter, sans-serif' }}> #1580</span> - STARGAZE
        </p>
                <div className="relative w-80 h-80 mb-4">
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02] blur-sm" 
            src="https://ipfs-gw.stargaze-apis.com/ipfs/Qmc5LA2qtmPvc8TB86VahHRFLnvqzADxZUinUbMeyyb4Rw/1580.png" 
            alt="Ammelia" 
            title="Ammelia #1580 details on Stargaze"
            onClick={() => window.open('https://www.stargaze.zone/m/ammelia/1580', '_blank')}
        />
                <div className="absolute inset-0 flex items-center justify-center">
                    <span className="text-red-600 text-6xl font-bold px-4 py-2 rotate-[-30deg]">
                        CLAIMED
                    </span>
                </div>
                <div style={{ paddingTop: '100%' }} /> {/* Maintains aspect ratio */}
                </div>
    </div>

    {/* Item 4 */}
    <div className="flex flex-col place-items-center">
        <p title="Geckies #2511 details on Stargaze" 
           onClick={() => window.open('https://www.stargaze.zone/m/stars166kqwcu8789xh7nk07fcrdzek54205u8gzas684lnas2kzalksqsg5xhqf/2511', '_blank')} 
           className="text-2xl mb-2 hover:text-lime-600 text-white cursor-pointer">
            <span className="text-4xl mb-2 text-white">{' '}8.{' '}</span>
            GECKIES <span style={{ fontFamily: 'Inter, sans-serif' }}> #2511</span> - STARGAZE
        </p>
                <div className="relative w-80 h-80 mb-4">
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02]" 
            src="https://ipfs-gw.stargaze-apis.com/ipfs/bafybeidkcrey4jiwj3ypxedbz2b7tsdyjtb7zcfr6smhevhkjjcsq2tigq/2511.png" 
            alt="Fomo Foxes" 
            title="Geckies #2511 details on Stargaze"
            onClick={() => window.open('https://www.stargaze.zone/m/stars166kqwcu8789xh7nk07fcrdzek54205u8gzas684lnas2kzalksqsg5xhqf/2511', '_blank')}
        />
                <div className="absolute inset-0 flex items-center justify-center cursor-pointer"
                onClick={() => window.open('https://asteroidprotocol.io/app/inscription/CEDAB3B152E263139C4D5240526D41857D231CB08BF1DCE3962339C0F32437E1', '_blank')}>
                    <span className="text-lime-500 bg-white text-4xl font-bold px-4 py-2 opacity-80">
                        WO7MS <span style={{ fontFamily: 'Inter, sans-serif' }}>#42</span>
                    </span>
                </div>
                <div style={{ paddingTop: '100%' }} /> {/* Maintains aspect ratio */}
                </div>
    </div>

    {/* Item 5 */}
    <div className="flex flex-col place-items-center">
        <p title="PandaWarrior #2825 details on Talis Injective" 
           onClick={() => window.open('https://injective.talis.art/nft/6701549aa55c4e6ee2f6b482?from=collection', '_blank')} 
           className="text-2xl mb-2 hover:text-lime-600 text-white cursor-pointer">
            <span className="text-4xl mb-2 text-white">{' '}9.{' '}</span>
            PANDAWARRIOR<span style={{ fontFamily: 'Inter, sans-serif' }}> #2825</span> - INJ
        </p>
                <div className="relative w-80 h-80 mb-4">
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02]" 
            src="./PandaWarrior2825.gif" 
            alt="PandaWarrior" 
            title="PandaWarrior #2825 details on Talis Injective"
            onClick={() => window.open('https://injective.talis.art/nft/6701549aa55c4e6ee2f6b482?from=collection', '_blank')}
        />
                 <div className="absolute inset-0 flex items-center justify-center cursor-pointer"
                onClick={() => window.open('https://asteroidprotocol.io/app/inscription/6BD4585EA30A2862A092B02F588E189C0C990BF2679C9AF6B4754179F269DD9A', '_blank')}>
                    <span className="text-lime-500 bg-white text-4xl font-bold px-4 py-2 opacity-80">
                        WO7MS <span style={{ fontFamily: 'Inter, sans-serif' }}>#608</span>
                    </span>
                </div>
                <div style={{ paddingTop: '100%' }} /> {/* Maintains aspect ratio */}
                </div>
    </div>

    {/* Item 7 */}
    <div className="flex flex-col place-items-center">
        <p title="Stargaze Mascot #2330 details on Stargaze" 
           onClick={() => window.open('https://www.stargaze.zone/m/sgmascotastro/2330', '_blank')} 
           className="text-2xl mb-2 hover:text-lime-600 text-white cursor-pointer">
            <span className="text-4xl mb-2 text-white">{' '}10.{' '}</span>
            STARGAZE MASCOT<span style={{ fontFamily: 'Inter, sans-serif' }}> #2330</span> - STARGAZE
        </p>
                <div className="relative w-80 h-80 mb-4">
        <img 
            className="cursor-pointer w-80 h-80 object-cover mb-4 hover:scale-[1.02]" 
            src="https://ipfs-gw.stargaze-apis.com/ipfs/bafybeihoq6v4mqtjjptsiulq5dtdc4qw2yxyylol4f37ujfkosihge2uva/2330.png" 
            alt="Stargaze Mascot" 
            title="Stargaze Mascot #2330 details on Stargaze"
            onClick={() => window.open('https://www.stargaze.zone/m/sgmascotastro/2330', '_blank')}
        />
                 <div className="absolute inset-0 flex items-center justify-center cursor-pointer"
                onClick={() => window.open('https://asteroidprotocol.io/app/inscription/28EDE99981FCCEC4428DB8970086847A68B10AD46FF798714A787E8735894A6B', '_blank')}>
                    <span className="text-lime-500 bg-white text-4xl font-bold px-4 py-2 opacity-80">
                        WO7MS <span style={{ fontFamily: 'Inter, sans-serif' }}>#607</span>
                    </span>
                </div>
                <div style={{ paddingTop: '100%' }} /> {/* Maintains aspect ratio */}
                </div>
    </div>


</div>

            {/* <p className="text-2xl mb-2 text-lime-500 mb-6">
                ... A <span className="text-2xl mb-2 text-white">BAD KID</span> and <span className="text-2xl mb-2 text-white">more prizes</span> to be added soon ...
            </p> */}
            
            <div ref={rulesRef} className="p-6 rounded-lg text-center text-lime-500 mt-4" style={{ fontFamily: 'Pixelify Sans, sans-serif' }}>
                <h2 className="text-3xl font-bold text-white mb-2">Rules :</h2>
                <ul className="text-lg md:text-2xl mb-4 list-disc list-inside text-white">
                    <li>For fairness to every participant, the winning WO7MS can't be a WO7MS gotten for FREE. It must have been minted during WL or PUBLIC.</li>
                    <li>On <span style={{ fontFamily: 'Inter, sans-serif' }}>27</span> February <span style={{ fontFamily: 'Inter, sans-serif' }}>2025</span>, some WO7MS will be randomly picked during a LIVE on Discord to win the prizes (max 1 prize per WO7MS).</li>
                    <li>Holders of the picked WO7MS will have to actively claim their prizes on discord ot X, prizes will only be sent to the address derivated from the one that held the WO7MS at the time of snapshot</li>
                    <li>Winners will have a 2 weeks window to claim their prize, else it will be re-raffled</li>
                </ul>
            </div>
            <video 
                className="w-full md:w-3/4 lg:w-1/2 mb-4 rounded-lg shadow-lg" 
                controls
                title="WO7MS Raffle Video"
            >
                <source src="./badmad.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="flex justify-center w-full pl-16 pb-6 mt-6">
                <img 
                    src="./madlearnmore.gif" 
                    alt="Scientist Learn more" 
                    className="cursor-pointer max-w-64 md:max-w-80 hover:scale-[1.05] hover:ml-1 pb-3" 
                    title="Learn more about Asteroid Protocol" 
                    onClick={() => window.open('https://docs.asteroidprotocol.io/asteroid-protocol-docs/general-overview', '_blank')}
                />
            </div>
            {/* Back to Top Button */}
            {!isAtTop && (
                <h1 
                className="fixed bottom-2 md:bottom-10 right-10  bg-black text-2xl text-white rounded-full p-3 shadow-lg hover:bg-lime-900 transition 
                 rounded-full border-2 border-solid border-lime-600 cursor-pointer"
                onClick={scrollToTop}
                title="Back to Top"
            >
                ↑
            </h1>
            )}
            
        </div>
    );
};

export default MintRafflePrizes;
